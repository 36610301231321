<template>
  <div>
    <div v-if="url === ''">
      <h2>Carregando...</h2>
    </div>
    <div v-else>
      <iframe :src="url" :height="windowHeight" width="100%" frameborder="0" allowfullscreen />
    </div>
  </div>
</template>
<script>
export default {
  name: 'PromotionTimeLine',
  data() {
    return {
      url: '',
      windowHeight: window.innerHeight - 100,
    }
  },
  async created() {
    const uuid = this.$route.params.pageId
    const response = await this.$http.get(`pages/${uuid}`)
    console.log(response)
    if (response.error) {
      return
    }

    this.url = response.url || null
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize() {
      this.windowHeight = window.innerHeight - 100
    },
  },
}
</script>

<style scoped>

</style>
